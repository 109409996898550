<template lang="">
  <div>
    <div class="custom-search d-flex align-items-center justify-content-between mb-1">
      <b-button
        variant="success"
        @click="openModal('add')"
      >
        เพิ่ม
      </b-button>
      <b-form-group style="    margin-bottom: 0rem;">
        <div class="d-flex align-items-center">
          <label class="mr-1">ค้นหา</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="ค้นหา"
            type="text"
            class="d-inline-block mr-1"
          />
        </div>
      </b-form-group>
    </div>
    <b-table
      striped
      hover
      responsive
      class="type-relative"
      :per-page="options.perPage"
      :current-page="options.currentPage"
      :items="listAgent"
      :fields="fields"
      :filter="searchTerm"
      show-empty
      @filtered="onFiltered"
    >
      <template #cell(button)="data">
        <div class="d-grid d-md-block">
          <button
            class="btn btn-warning mr-1"
            type="button"
            @click="openModal('edit', data.item,data.item._id)"
          >
            แก้ไข
          </button>
          <button
            class="btn btn-danger mr-1"
            type="button"
            @click="removeAgent(data.item.id)"
          >
            ลบ
          </button>
        </div>
      </template>
      <template #empty>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลเอเจนต์
          </h4>
        </div>
      </template>
      <template #emptyfiltered>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลเอเจนต์
          </h4>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-if="totalItem > 0"
      class="d-flex justify-content-between flex-wrap pt-0 mt-2"
    >

      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="options.perPage"
          size="sm"
          inline
          :options="options.pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="options.currentPage"
          :total-rows="options.totalRows"
          :per-page="options.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <modal-agent
      :id="userid"
      ref="addagent"
      :title="typeModal"
      @setCloseModal="setCloseModal"
      @LoadList="LoadList"
    />
  </div>
</template>
<script>
import {
  BCardBody, BPagination, BFormSelect, BTable, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalAgent from './addAgent.vue'

export default {
  components: {
    BCardBody,
    BPagination,
    BFormSelect,
    BTable,
    BFormInput,
    BFormGroup,
    BButton,
    ModalAgent,
  },
  data() {
    return {
      typeModal: '',
      userid: '',
      listAgent: [],
      searchTerm: '',
      options: {
        perPage: 10,
        currentPage: 1,
        pageOptions: [10, 25, 50, 100],
        totalRow: 10,
      },
      totalItem: 0,
      fields: [
        {
          key: 'name',
          label: 'ชื่อ',
          sortable: true,
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:70px',
        },
        {
          key: 'agent',
          label: 'ชื่อเอเจนต์',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:120px',
        },
        {
          key: 'key',
          label: 'Key',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:120px',
        },
        {
          key: 'endpoint',
          label: 'Endpoint',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:100px',
        },
        {
          key: 'clientName',
          label: 'ClientName',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:100px',
        },
        {
          key: 'button',
          label: 'จัดการ',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:250px',
        },
      ],
    }
  },
  computed: {
    checkEmptyTable() {
      return this.listAgent.length > 0
    },
  },
  created() {
    this.LoadList()
  },
  methods: {
    setCloseModal() {
      this.userid = ''
    },
    async LoadList() {
      try {
        const result = await this.$store.dispatch('agent/getAllAgent')
        this.listAgent = result.data.items
        this.totalItem = result.data.totalItem
        this.options.totalRows = result.data.totalItem
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    },
    onFiltered(FilterItem) {
      this.options.totalRow = FilterItem.length
      this.options.currentPage = 1
    },
    removeAgent(id) {
      if (id !== '' && id !== undefined) {
        this.$swal({
          title: 'คุณต้องการจะลบข้อมูลนี้?',
          text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-success mr-1',
          confirmButtonText: 'ยืนยัน',
          cancelButtonClass: 'btn-danger',
          cancelButtonText: 'ยกเลิก',
        }).then(result => {
          if (result.value) {
            this.$store
              .dispatch('agent/removeAgent', { id })
              .then(response => {
                if (response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ลูกค้า',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: response.data.message,
                    },
                  })
                  this.LoadList()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ลูกค้า',
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.message,
                  },
                })
              })
          }
        })
      }
    },
    openModal(name, data, id) {
      let filter = ''
      if (name === 'add') {
        filter = 'เพิ่มข้อมูลเอเจนต์'
      } else if (name === 'edit') {
        filter = 'แก้ไขข้อมูลเอเจนต์'
        this.userid = id
      }
      this.typeModal = filter
      this.$refs.addagent.show()
    },
    closeModal() {
      this.$refs.addagent.close()
    },
  },
}
</script>
<style lang="">

</style>
