<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      header-bg-variant="primary"
      hide-footer
    >
      <h4>
        {{ title }}
      </h4>

      <validation-observer
        ref="agentRules"
      >
        <validation-provider
          #default="{ errors }"
          name="agentName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ชื่อ</label>
            <b-form-input
              id="agentName"
              v-model="input.Name"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อ Agent"
              autofocus
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกชื่อ</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="agentName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ชื่อเอเจนต์</label>
            <b-form-input
              id="agentName"
              v-model="input.Agent"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อ Agent"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกชื่อเอเจนต์</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="agentUrl"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>Endpoint</label>
            <b-form-input
              id="agentUrl"
              v-model="input.Endpoint"
              :state="errors.length > 0 ? false:null"
              placeholder="URL"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก Endpoint </small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="agentKey"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>คีย์ Agent</label>
            <b-form-input
              id="agentKey"
              v-model="input.Key"
              :state="errors.length > 0 ? false:null"
              placeholder="คีย์ Agent"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกคีย์ของเอเจนต์</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="agentKey"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ClientName</label>
            <b-form-input
              id="clientname"
              v-model="input.clientName"
              :state="errors.length > 0 ? false:null"
              placeholder="ClientName"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก ClientName</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="agentKey"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>Hash</label>
            <b-form-input
              id="hash"
              v-model="input.hash"
              :state="errors.length > 0 ? false:null"
              placeholder="Hash"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก Hash</small>
        </validation-provider>
      </validation-observer>
      <div class="row mt-2 d-flex justify-content-end">
        <b-button
          variant="danger"
          class="mr-1"
          @click="close"
        >
          ยกเลิก
        </b-button>
        <b-button
          v-if="title === 'เพิ่มข้อมูลเอเจนต์'"
          variant="success"
          class="mr-1"
          :disabled="isConfirm"
          @click="addAgent"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> ยืนยัน
        </b-button>
        <b-button
          v-if="title === 'แก้ไขข้อมูลเอเจนต์'"
          variant="warning"
          class="mr-1"
          :disabled="isConfirm"
          @click="editAgent"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> แก้ไข
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormInput,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      openModal: false,
      input: {
        id: '',
        Name: '',
        Agent: '',
        Endpoint: '',
        Key: '',
        clientName: '',
        hash: '',
      },
      required,
      isConfirm: false,
    }
  },
  watch: {
  },
  beforeUpdate() {
    if (this.id !== '') {
      this.getinfo()
    }
  },
  methods: {
    show() {
      this.openModal = true
      this.defaultData()
    },
    close() {
      this.openModal = false
    },
    addAgent() {
      this.isConfirm = true
      this.$refs.agentRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('agent/addAgent', this.input).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    editAgent() {
      this.isConfirm = true
      this.$refs.agentRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('agent/editAgent', this.input).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    getinfo() {
      this.$store.dispatch('agent/getAgentbyid', { id: this.id }).then(response => {
        console.log(response)
        this.input.id = this.id
        this.input.Name = response.data.item.name
        this.input.Endpoint = response.data.item.endpoint
        this.input.Key = response.data.item.key
        this.input.Agent = response.data.item.agent
        this.input.clientName = response.data.item.clientName
        this.input.hash = response.data.item.hash
      })
    },
    defaultData() {
      this.input.id = this.id
      this.input.Name = ''
      this.input.Endpoint = ''
      this.input.Key = ''
      this.input.Agent = ''
      this.input.clientName = ''
      this.input.hash = ''
      this.isConfirm = false
    },
  },
}
</script>
