var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"centered":"","header-bg-variant":"primary","hide-footer":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('validation-observer',{ref:"agentRules"},[_c('validation-provider',{attrs:{"name":"agentName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("ชื่อ")]),_c('b-form-input',{attrs:{"id":"agentName","state":errors.length > 0 ? false:null,"placeholder":"ชื่อ Agent","autofocus":""},model:{value:(_vm.input.Name),callback:function ($$v) {_vm.$set(_vm.input, "Name", $$v)},expression:"input.Name"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อ")]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"agentName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("ชื่อเอเจนต์")]),_c('b-form-input',{attrs:{"id":"agentName","state":errors.length > 0 ? false:null,"placeholder":"ชื่อ Agent"},model:{value:(_vm.input.Agent),callback:function ($$v) {_vm.$set(_vm.input, "Agent", $$v)},expression:"input.Agent"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อเอเจนต์")]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"agentUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("Endpoint")]),_c('b-form-input',{attrs:{"id":"agentUrl","state":errors.length > 0 ? false:null,"placeholder":"URL"},model:{value:(_vm.input.Endpoint),callback:function ($$v) {_vm.$set(_vm.input, "Endpoint", $$v)},expression:"input.Endpoint"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอก Endpoint ")]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"agentKey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("คีย์ Agent")]),_c('b-form-input',{attrs:{"id":"agentKey","state":errors.length > 0 ? false:null,"placeholder":"คีย์ Agent"},model:{value:(_vm.input.Key),callback:function ($$v) {_vm.$set(_vm.input, "Key", $$v)},expression:"input.Key"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกคีย์ของเอเจนต์")]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"agentKey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("ClientName")]),_c('b-form-input',{attrs:{"id":"clientname","state":errors.length > 0 ? false:null,"placeholder":"ClientName"},model:{value:(_vm.input.clientName),callback:function ($$v) {_vm.$set(_vm.input, "clientName", $$v)},expression:"input.clientName"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอก ClientName")]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"agentKey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-md-12 mt-1"},[_c('label',[_vm._v("Hash")]),_c('b-form-input',{attrs:{"id":"hash","state":errors.length > 0 ? false:null,"placeholder":"Hash"},model:{value:(_vm.input.hash),callback:function ($$v) {_vm.$set(_vm.input, "hash", $$v)},expression:"input.hash"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอก Hash")]):_vm._e()]}}])})],1),_c('div',{staticClass:"row mt-2 d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":_vm.close}},[_vm._v(" ยกเลิก ")]),(_vm.title === 'เพิ่มข้อมูลเอเจนต์')?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","disabled":_vm.isConfirm},on:{"click":_vm.addAgent}},[(_vm.isConfirm)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" ยืนยัน ")],1):_vm._e(),(_vm.title === 'แก้ไขข้อมูลเอเจนต์')?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","disabled":_vm.isConfirm},on:{"click":_vm.editAgent}},[(_vm.isConfirm)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" แก้ไข ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }